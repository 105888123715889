import { Action, createReducer, on } from '@ngrx/store';
import { Deal, Meta } from '../../models';
import * as DealActions from '../actions';

export interface DealState {
  entities: Deal[];
  selectedEntity?: Deal;
  meta?: Meta;
  loading: boolean;
  error: boolean;
}

export const initialState: DealState = {
  entities: [],
  meta: {
    currentPage: 0,
    nextPage: 0,
    prevPage: 0,
    totalPages: 0,
    totalCount: 0
  },
  loading: false,
  error: false
};

const dealReducer = createReducer(
  initialState,
  on(DealActions.createDeal, state => ({ ...state, loading: true })),
  on(DealActions.createDealSuccess, (state, { deal }) => ({
    ...state,
    loading: false,
    entities: [...state.entities, { ...deal }]
  })),
  on(DealActions.createDealError, state => ({ ...state, loading: false })),
  on(DealActions.getDeals, state => ({ ...state, loading: true })),
  on(DealActions.getDealsError, state => ({ ...state, loading: false })),
  on(DealActions.getDealsSuccess, (state, { deals, meta }) => {
    return {
      ...state,
      loading: false,
      entities: deals,
      meta: meta
    };
  }),
  on(DealActions.getDeal, state => ({ ...state, loading: true })),
  on(DealActions.getDealError, state => ({ ...state, loading: false })),
  on(DealActions.getDealSuccess, (state, { deal }) => ({
    ...state,
    loading: false,
    selectedDeal: deal
  })),
  on(DealActions.updateDeal, (state, { deal }) => ({
    ...state,
    entities: state.entities.map(h => {
      if (h.id === deal.id) {
        state.loading = true;
      }
      return h;
    })
  })),
  on(DealActions.updateDealSuccess, (state, { deal }) =>
  {
    return state;
  }
  ),
  on(DealActions.updateDealError, (state, { error }) => ({
    ...state,
    entities: state.entities.map(h => {
      if (h.id === error.requestData.id) {
        // Huh? No idea what the error is!
        state.error = true;
      }
      return h;
    }),
    loading: false
  })),
  on(DealActions.setDealLoading, (state, { loading }) => ({
    ...state,
    loading: loading == null ? true : loading
  }))
);

export function reducer(state: DealState | undefined, action: Action) {
  return dealReducer(state, action);
}
