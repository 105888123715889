import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResource } from './base.resource';

@Injectable({
  providedIn: 'root'
})
export class CustomerResource extends BaseResource {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = 'api/customers';
  }
}
