import { createAction, props } from '@ngrx/store';

import { InstitutionCompany, Meta } from '../../models';
import { DataServiceError } from '../../errors';

export const createInstitutionCompanyAction = (actionType: string) =>
  createAction(actionType, props<{ institutionCompany: InstitutionCompany }>());

export const createInstitutionCompanyErrorAction = (actionType: string) =>
  createAction(
    actionType,
    props<{ error: DataServiceError<InstitutionCompany> }>()
  );

export const getInstitutionCompanies = createAction(
  '[Institution Company] GET_INSTITUTION_COMPANIES',
  props<{ params: any }>()
);

export const getInstitutionCompaniesSuccess = createAction(
  '[Institution Company] GET_INSTITUTION_COMPANIES_SUCCESS',
  props<{ institutionCompanies: InstitutionCompany[]; meta: Meta }>()
);

export const getInstitutionCompaniesError = createInstitutionCompanyErrorAction(
  '[Institution Company] GET_INSTITUTION_COMPANIES_ERROR'
);

export const createInstitutionCompany = createInstitutionCompanyAction(
  '[Institution Company] CREATE_INSTITUTION_COMPANY'
);

export const createInstitutionCompanySuccess = createInstitutionCompanyAction(
  '[Institution Company] CREATE_INSTITUTION_COMPANY_SUCCESS'
);

export const createInstitutionCompanyError = createInstitutionCompanyErrorAction(
  '[Institution Company] CREATE_INSTITUTION_COMPANY_ERROR'
);

export const getInstitutionCompany = createAction(
  '[Institution Company] GET_INSTITUTION_COMPANY',
  props<{ id: string }>()
);

export const getInstitutionCompanySuccess = createInstitutionCompanyAction(
  '[Institution Company] GET_INSTITUTION_COMPANY_SUCCESS'
);

export const getInstitutionCompanyError = createInstitutionCompanyErrorAction(
  '[Institution Company] GET_INSTITUTION_COMPANY_ERROR'
);

export const updateInstitutionCompany = createInstitutionCompanyAction(
  '[Institution Company] UPDATE_INSTITUTION_COMPANY'
);

export const updateInstitutionCompanySuccess = createInstitutionCompanyAction(
  '[Institution Company] UPDATE_INSTITUTION_COMPANY_SUCCESS'
);

export const updateInstitutionCompanyError = createInstitutionCompanyErrorAction(
  '[Institution Company] UPDATE_INSTITUTION_COMPANY_ERROR'
);

export const setInstitutionCompanyLoading = createAction(
  '[Institution Company] SET_INSTITUTION_COMPANY_LOADING',
  props<{ loading: boolean }>()
);
