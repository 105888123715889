import { Component } from '@angular/core';

import { BaseComponent } from '@clemos/core';

@Component({
  selector: 'clemos-lead',
  templateUrl: 'lead.component.html'
})
export class LeadComponent extends BaseComponent {
  constructor() {
    super();
  }
}
