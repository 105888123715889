import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Response } from '../models';
import { DataServiceError } from '../errors/data-service';

export abstract class BaseResource {
  url = `/leads/`;

  constructor(protected http: HttpClient) {}

  create<T>(data: T): Observable<T> {
    return this.http
      .post<T>(this.url, data)
      .pipe(catchError(this.handleError(data)));
  }

  query(params?: any): Observable<Response> {
    return this.http
      .get<Response>(this.url, { params: params })
      .pipe(catchError(this.handleError()));
  }

  get(id: string): Observable<Response> {
    return this.http
      .get<Response>(`${this.url}${id}`)
      .pipe(catchError(this.handleError()));
  }

  // update<T>(data: T): Observable<Response> {
  //   return this.http.put<T>(`${this.url}${data.id}`, data).pipe(
  //     map(res => res.data), // return the updated ta
  //     catchError(this.handleError(res))
  //   );
  // }

  protected handleError<T>(requestData?: T) {
    return (res: HttpErrorResponse) => {
      const error = new DataServiceError(res.error, requestData);
      console.error(error);
      // return new ErrorObservable(error);
      return throwError(error);
    };
  }
}
