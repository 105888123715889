import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Deal } from '../../models';
import * as DealAction from '../actions';
import { EntityState } from '../reducers';

@Injectable()
export class DealDispatchers {

  constructor(private store: Store<EntityState>) {}

  createDeal(deal: Deal) {
    this.store.dispatch(DealAction.createDeal({ deal }));
  }

  updateDeal(deal: Deal) {
    this.store.dispatch(DealAction.updateDeal({ deal }));
  }

  getDeals(params?) {
    this.store.dispatch(DealAction.getDeals({ params }));
  }

  getDeal(id: string) {
    this.store.dispatch(DealAction.getDeal({ id }));
  }
}
