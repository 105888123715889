// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { ResourceService } from 'ng-restly';

// @Injectable({
//   providedIn: 'root'
// })
// export class LeadResource extends ResourceService {
//   constructor(protected http: HttpClient) {
//     super(http);
//     this.url = 'api/leads/:id';
//   }

//   assign(id: string, coachId?: string) {
//     this.url = 'api/leads/:id/assign';

//     const data = {
//       lead: {
//         assignment: {
//           assigneeId: coachId
//         }
//       }
//     };
//     const ret = super.save(data, { id: id });

//     this.url = 'api/leads/:id';

//     return ret;
//   }
// }

import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Lead, Response } from '../models';
import { DataServiceError } from '../errors/data-service';
import { BaseResource } from './base.resource';

@Injectable({
  providedIn: 'root'
})
export class LeadResource extends BaseResource {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = 'api/leads/';
  }

  assign(coachId: string): Observable<any> {
    return this.http
      .get<any>(`${this.url}/assign`, { params: { coachId: coachId } })
      .pipe(
        map(res => res.data),
        catchError(this.handleError())
      );
  }
}
