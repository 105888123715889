import { Injectable } from '@angular/core';
import { Store, createSelector, createFeatureSelector } from '@ngrx/store';
import { LeadWizardState } from '../reducers/lead-wizard.reducer';
import { EntityState } from '../reducers';

// selectors
const getCometState = createFeatureSelector<EntityState>('clemosCore');

const getLeadWizardState = createSelector(
    getCometState,
  (state: EntityState) => state.leadWizard
);

const getLead = createSelector(
    getLeadWizardState,
  (state: LeadWizardState) => state.lead
);

const getCustomer = createSelector(
    getLeadWizardState,
  (state: LeadWizardState) => state.customer
);

const getFormControl = createSelector(
    getLeadWizardState,
  (state: LeadWizardState) => state.formControl
);

const getSubmitting = createSelector(
    getLeadWizardState,
    (state: LeadWizardState) => state.submitting
  );


@Injectable()
export class LeadWizardSelectors {
  constructor(private store: Store<EntityState>) {}
  // selectors$
  lead$ = this.store.select(getLead);
  customer$ = this.store.select(getCustomer);
  formControl$ = this.store.select(getFormControl);
  submitting$ = this.store.select(getSubmitting);
}
