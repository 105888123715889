import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

// Object-Path
import * as objectPath from 'object-path';

// libs
import { Profile, Coach } from '@clemos/core/models';

// import { LayoutState } from '../../store/reducers/layout.state';
import { untilDestroy } from '@clemos/utils';

@Component({
  selector: 'clemos-exoplanets-inside',
  templateUrl: './inside.component.html',
  styleUrls: ['./inside.component.scss']
})
export class InsideComponent implements OnInit, OnDestroy {
  // @Select(LayoutState.showAside) showAside$: Observable<boolean>;
  // @Select(LayoutState.showSidebar) showSidebar$: Observable<boolean>;

  private currentUser: Coach;
  private currentUserAuth: any;
  private currentUserSubscription: Subscription;

  constructor(
    // private route: ActivatedRoute  
    ) {
    // this.currentUser = this.route.snapshot.data['currentUser'];
  }

  @HostBinding('class.sidebar-open') showSidebar: boolean;
  @HostBinding('class.aside-open') showAside: boolean;

  // closeSidenav() {}

  // setCurrentUser() {}

  // getShowSidebar() {
  //   this.showSidebar$.pipe(untilDestroy(this)).subscribe(state => {
  //     this.showSidebar = state;
  //   });
  // }

  // getShowAside() {
  //   this.showAside$.pipe(untilDestroy(this)).subscribe(state => {
  //     this.showAside = state;
  //   });
  // }

  ngOnInit() {
    // this.setCurrentUser();
    // this.getShowSidebar();
    // this.getShowAside();
  }

  ngOnDestroy() {}
}
