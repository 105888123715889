import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  OnDestroy
} from '@angular/core';
// import { Select, Store } from '@ngxs/store';
// import {
//   LayoutState,
//   CloseSidebar,
//   CloseAside
// } from '../../store/reducers/layout.state';
import { Observable } from 'rxjs';
import { untilDestroy } from '@clemos/utils';

@Component({
  selector: 'clemos-exoplanets-backdrop',
  template: ``,
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent implements OnInit, OnDestroy {

  isShowAside: boolean;
  isShowSidebar: boolean;

  constructor(
    // private store: Store
    ) {}

  @HostBinding('class.app-backdrop') isShow: boolean;
  @HostBinding('class.backdrop-sidebar') isShowBackDropSidebar: boolean;

  @HostListener('click') close() {
    // this.store.dispatch(new CloseSidebar());
    // this.store.dispatch(new CloseAside());
  }

  getIsShow() {
    this.isShow = this.isShowSidebar || this.isShowAside;
  }

  getIsShowBackDropSidebar() {
    this.isShowBackDropSidebar = this.isShowSidebar || this.isShowAside;
  }

  getIsShowSidebar() {
    // this.showSidebar$.pipe(untilDestroy(this)).subscribe(state => {
    //   this.isShowSidebar = state;
    //   this.getIsShowBackDropSidebar();
    //   this.getIsShow();
    // });
  }

  getIsShowAside() {
    // this.showAside$.pipe(untilDestroy(this)).subscribe(state => {
    //   this.isShowAside = state;
    //   this.getIsShowBackDropSidebar();
    //   this.getIsShow();
    // });
  }

  ngOnInit(): void {
    this.getIsShowSidebar();
    this.getIsShowAside();
  }

  ngOnDestroy(): void {}
}
