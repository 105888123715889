import { Injectable } from '@angular/core';
import { Store, createSelector, createFeatureSelector } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { EntityState } from '../reducers';
import { Lead } from '../../models';
import * as LeadAction from '../actions';
import { LeadState } from '../reducers/lead.reducer';

// selectors
const getEntityState = createFeatureSelector<EntityState>('clemosCore');

const getLeadState = createSelector(
  getEntityState,
  (state: EntityState) => state.leads
);

const getAllLeads = createSelector(
  getLeadState,
  (state: LeadState) => state.entities
);

const getCurrentLead = createSelector(
  getLeadState,
  (state: LeadState) => state.selectedEntity
);

const getLeadsLoading = createSelector(
  getLeadState,
  (state: LeadState) => state.loading
);

const getMeta = createSelector(
  getLeadState,
  (state: LeadState) => state.meta
);


@Injectable()
export class LeadSelectors {
  constructor(private store: Store<EntityState>) {}
  // selectors$
  leads$ = this.store.select(getAllLeads);
  lead$ = this.store.select(getCurrentLead);
  leadState$ = this.store.select(getLeadState);
  loading$ = this.store.select(getLeadsLoading);
  meta$ = this.store.select(getMeta);
}
