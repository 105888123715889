import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// libs
import { throwIfAlreadyLoaded } from '@clemos/utils';
import {
  CoreModule,
  PlatformWindowToken
} from '@clemos/core';
import { FormConfigModule } from './config/form.config';

import {NgxLoaderIndicatorModule} from 'ngx-loader-indicator'

// bring in custom web services here...

// factories
export function winFactory() {
  return window;
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot([
      {
        provide: PlatformWindowToken,
        useFactory: winFactory
      }
    ]),
    FormConfigModule,
    NgxLoaderIndicatorModule.forRoot()

  ]
})
export class ClemosCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ClemosCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'ClemosCoreModule');
  }
}
