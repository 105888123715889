import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as LeadWizardActions from '../actions/lead-wizard.actions';
import { LeadResource } from '../../resources';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LeadWizardEffects {
  submit$ = createEffect(() =>
    this.actions$.pipe(
    //   ofType(LeadWizardActions.submit),
    //   switchMap(action =>
    //     this.leadResource.save().pipe(
    //       map(res =>
    //         LeadWizardActions.submitSuccess()
    //       ),
    //       catchError(error =>
    //         of(LeadWizardActions.submitError({ error }))
    //       )
    //     )
    //   )
    )
  );

  submitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LeadWizardActions.submitLeadWizardSuccess),
        tap(() => this.toastrService.success('Lead created successful')),
        tap(action =>
          this.router.navigate(['/inside/transactions'])
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private leadResource: LeadResource,
    private router: Router,
    private ngbModel: NgbModal,
    private toastrService: ToastrService
  ) {}
}
