import { NgModule } from '@angular/core';
import { LeadModule as SharedLeadModule } from '@clemos/features';
import { UIModule } from '../ui/ui.module';
import { LEAD_COMPONENTS } from './components';

@NgModule({
  imports: [SharedLeadModule, UIModule],
  declarations: [...LEAD_COMPONENTS],
  exports: [...LEAD_COMPONENTS]
})
export class LeadModule {}
