import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Lead } from '../../models';
import * as LeadAction from '../actions';
import { EntityState } from '../reducers';

@Injectable()
export class LeadDispatchers {

  constructor(private store: Store<EntityState>) {}

  createLead(lead: Lead) {
    this.store.dispatch(LeadAction.createLead({ lead }));
  }

  updateLead(lead: Lead) {
    this.store.dispatch(LeadAction.updateLead({ lead }));
  }

  getLeads(params?) {
    this.store.dispatch(LeadAction.getLeads({ params }));
  }

  getLead(id: string) {
    this.store.dispatch(LeadAction.getLead({ id }));
  }
}
