import { Injectable } from '@angular/core';
import { Store, createSelector, createFeatureSelector } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { EntityState } from '../reducers';
import { Deal } from '../../models';
import * as DealAction from '../actions';
import { DealState } from '../reducers/deal.reducer';

// selectors
const getEntityState = createFeatureSelector<EntityState>('clemosCore');

const getDealState = createSelector(
  getEntityState,
  (state: EntityState) => state.deals
);

const getAllDeals = createSelector(
  getDealState,
  (state: DealState) => state.entities
);

const getCurrentDeal = createSelector(
  getDealState,
  (state: DealState) => state.selectedEntity
);

const getDealsLoading = createSelector(
  getDealState,
  (state: DealState) => state.loading
);

const getMeta = createSelector(
  getDealState,
  (state: DealState) => state.meta
);


@Injectable()
export class DealSelectors {
  constructor(private store: Store<EntityState>) {}
  // selectors$
  deals$ = this.store.select(getAllDeals);
  deal$ = this.store.select(getCurrentDeal);
  dealState$ = this.store.select(getDealState);
  loading$ = this.store.select(getDealsLoading);
  meta$ = this.store.select(getMeta);
}
