import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio-buttons-group',
  template: `
    <div class="radio-buttons-group">
      <div
        *ngFor="
          let option of (to.options | formlySelectOptions: field | async);
          let i = index
        "
        class="radio-container"
      >
        <input
          type="radio"
          [id]="id + '_' + i"
          [class.form-check-input]="to.formCheck !== 'custom'"
          [class.custom-control-input]="to.formCheck === 'custom'"
          [name]="id"
          [class.is-invalid]="showError"
          [attr.value]="option.value"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          class="radio-button"
        />
        <div class="radio-tile">
          <span
            class="radio-tile-label"
            [class.form-check-label]="to.formCheck !== 'custom'"
            [class.custom-control-label]="to.formCheck === 'custom'"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
  `
})
export class FormlyFieldRadioButtonsGroup extends FieldType {}
