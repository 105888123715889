import { LeadEffects } from './lead.effects';
import { DealEffects } from './deal.effects';
import { LeadWizardEffects } from './lead-wizard.effects';

export const effects: any[] = [
    LeadEffects, 
    DealEffects, 
    // LeadWizardEffects
];

export * from './lead.effects';
export * from './deal.effects';
export * from './lead-wizard.effects';
