import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
    <div [ngSwitch]="type">
      <input
        *ngSwitchCase="'number'"
        type="number"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchCase="'currency'"
        currencyMask
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchCase="'date'"
        [textMask]="{ mask: dateMask }"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchCase="'month'"
        [textMask]="{ mask: monthMask }"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchCase="'year'"
        [textMask]="{ mask: yearMask }"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchCase="'postalCode'"
        [textMask]="{ mask: postalCodeMask }"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        appFloat
      />
      <input
        *ngSwitchDefault
        [type]="type"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
      />
    </div>
  `
})
export class FormlyFieldInput extends FieldType {
  dateMask = [
    /[0-3]/,
    /\d/,
    '-',
    /[0-1]/,
    /\d/,
    '-',
    /[1]/,
    /[9]/,
    /[4-9]/,
    /\d/
  ];
  monthMask = [/[0-1]/, /\d/, '-', /[1]/, /[9]/, /[4-9]/, /\d/];
  yearMask = [/[1]/, /[9]/, /[4-9]/, /\d/];
  postalCodeMask = [/\d/, /\d/, /\d/, /\d/];

  get type() {
    return this.to.type || 'text';
  }
}
