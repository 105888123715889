export * from './geo/country.resource';
export * from './geo/state.resource';

export * from './messenger/conversation.resource';
export * from './messenger/message.resource';

export * from './institution/user.resource';
export * from './institution/branch.resource';
export * from './institution/company.resource';

export * from './bank.resource';
export * from './coach.resource';
export * from './customer.resource';
export * from './deal.resource';
export * from './document.resource';
export * from './lead.resource';
export * from './note.resource';
export * from './phone.resource';
export * from './task.resource';
