import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-horizontal-wrapper',
  template: `
    <div class="form-group row" [class.has-error]="showError">
      <div class="col-sm-4">
        <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id">
          {{ to.label | translate }}
          <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
        </label>
      </div>
      <div class="col-sm-8">
        <ng-template #fieldComponent></ng-template>
        <div
          *ngIf="showError"
          class="invalid-feedback"
          [style.display]="'block'"
        >
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
        <small *ngIf="to.description" class="form-text text-muted">{{
          to.description
        }}</small>
      </div>
    </div>
  `
})
export class FormlyHorizontalWrapper extends FieldWrapper {
}
