import { Component, OnInit } from '@angular/core';
// import { ToggleAside } from '../../store/reducers/layout.state';

@Component({
  selector: 'clemos-exoplanets-aside-toggler',
  templateUrl: './aside-toggler.component.html',
  styleUrls: ['./aside-toggler.component.scss']
})
export class AsideTogglerComponent {
  constructor(
    // private store: Store
    ) {}

  toggleAside() {
    // this.store.dispatch(new ToggleAside());
  }
}
