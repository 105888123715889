import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as DealActions from '../actions';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DealResource } from '../../resources';

@Injectable()
export class DealEffects {
  getDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActions.getDeals),
      switchMap(action =>
        this.dealResource.query(action.params).pipe(
          map(res => {

            console.log('deal effects', res.data);
            return DealActions.getDealsSuccess({
              deals: res.data,
              meta: res.meta
            });
          }),
          catchError(error => of(DealActions.getDealsError({ error })))
        )
      )
    )
  );

  getDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActions.getDeal),
      switchMap(action =>
        this.dealResource.get(action.id).pipe(
          map(res => DealActions.getDealSuccess({ deal: res.data })),
          catchError(error => of(DealActions.getDealError({ error })))
        )
      )
    )
  );

  //   addDeal$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(DealActions.addDeal),
  //       concatMap(action =>
  //         this.dealDataService.addDeal(action.deal).pipe(
  //           map(deal => DealActions.addDealSuccess({ deal })),
  //           catchError(error => of(DealActions.addDealError({ error })))
  //         )
  //       )
  //     )
  //   );

  //   addDealSuccess$ = createEffect(
  //     () =>
  //       this.actions$.pipe(
  //         ofType(DealActions.addDealSuccess),
  //         tap(() => this.toastrService.success('Deal created successful')),
  //         tap(() => this.ngbModel.dismissAll()),
  //         tap(action => this.router.navigate(['/inside/deals', action.deal.id]))
  //       ),
  //     { dispatch: false }
  //   );

  //   updateDeal$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(DealActions.updateDeal),
  //       concatMap(action =>
  //         this.dealDataService.updateDeal(action.deal).pipe(
  //           map(deal => DealActions.updateDealSuccess({ deal })),
  //           catchError(error => of(DealActions.updateDealError({ error })))
  //         )
  //       )
  //     )
  //   );

  //   updateDealSuccess$ = createEffect(
  //     () =>
  //       this.actions$.pipe(
  //         ofType(DealActions.updateDealSuccess),
  //         tap(() => this.toastrService.success('Deal updated successful'))
  //       ),
  //     { dispatch: false }
  //   );

  //   getDealTransactions$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(DealActions.getDealTransactions),
  //       switchMap(action =>
  //         this.transactionDataService.getTransactions(action.page, action.dealIdentifier).pipe(
  //           map(res =>
  //             DealActions.getDealTransactionsSuccess({
  //               transactions: res.data,
  //               meta: res.meta
  //             })
  //           ),
  //           catchError(error =>
  //             of(DealActions.getDealTransactionsError({ error }))
  //           )
  //         )
  //       )
  //     )
  //   );

  constructor(
    private actions$: Actions,
    private dealResource: DealResource,
    private router: Router,
    private ngbModel: NgbModal,
    private toastrService: ToastrService
  ) {}
}
