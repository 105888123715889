import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const camelcase = require('camelcase');

@Injectable({
    providedIn: 'root'
})
export class PropertyOptions {
    propertyPurpose = [
        { value: 'purchase', label: 'Kauf' },
        { value: 'renovation', label: 'Sanierung / Umbau' },
        { value: 'debt_restructuring', label: 'Umschuldung' },
        { value: 'others', label: 'Sonstige' }
    ];

    propertyType = [
        { value: 'condominium', label: 'Eigentumswohnung' },
        { value: 'house', label: 'Haus' },
        { value: 'plot', label: 'Grundstück' },
        { value: 'others', label: 'Sonstige' }
    ];

    equipmentType = [
        { value: 'good', label: 'Gut' },
        { value: 'middel', label: 'Mittel' },
        { value: 'elevated', label: 'Gehoben' }
    ]


    get(key): Observable<any[]> {
        return of(this[camelcase(key)]);
    }
}
