import { Injectable } from '@angular/core';
import { Store, createSelector, createFeatureSelector } from '@ngrx/store';

import { EntityState } from '../reducers';
import { MenuState } from '../reducers/menu.reducer';

// selectors
const getEntityState = createFeatureSelector<EntityState>('clemosCore');

const getMenuState = createSelector(
  getEntityState,
  (state: EntityState) => state.menu
);

const getSidebarMenu = createSelector(
  getMenuState,
  (state: MenuState) => state.sidebar
);

const getTopbarMenu = createSelector(
  getMenuState,
  (state: MenuState) => state.topbar
);

@Injectable()
export class MenuSelectors {
  constructor(private store: Store<EntityState>) {}
  // selectors$
  sidebar$ = this.store.select(getSidebarMenu);
  topbar$ = this.store.select(getTopbarMenu);
}
