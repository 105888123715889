import { Component } from '@angular/core';
import { AppBaseComponent } from '@clemos/web';

@Component({
  selector: 'clemos-inside',
  templateUrl: './inside.component.html',
  styleUrls: ['./inside.component.scss']
})
export class InsideComponent extends AppBaseComponent {

}
