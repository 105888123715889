import { createAction, props } from '@ngrx/store';
import { MenuItem } from '@clemos/core';

export const setSidebarMenu = createAction(
  '[Layout] SET_SIDEBAR_MENU',
  props<{ menuItems: MenuItem[] }>()
);
export const setTopbarMenu = createAction(
  '[Layout] SET_TOPBAR_MENU',
  props<{ menuItems: MenuItem[] }>()
);

export const setUserMenu = createAction(
  '[Layout] SET_USER_MENU',
  props<{ menuItems: MenuItem[] }>()
);
