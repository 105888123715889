import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Phone } from '@clemos/core';
import { BaseResource } from './base.resource';

@Injectable({
  providedIn: 'root'
})
export class PhoneResource extends BaseResource {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = 'api/phones';
  }

  requestVerification(number: string) {
    /*
          Assume you have an endpoint that returns all the posts with their the comments
          Change the url temporarily to that endpoint
        */
    this.url = 'api/phones/request_verification';
    /*
           Use the method your api require using super from super class ResourceService
           Don't return it just yet, store it in a variable
         */
    const ret = super.create({ phone: { number: number } });
    // Change the url back to default baseUrl
    this.url = 'api/phones/:id';
    // Now Return
    return ret;
  }

  verify(phone: Phone) {
    this.url = 'api/phones/verify';
    const ret = super.create({
      phone: {
        number: phone.number,
        verificationCode: phone.verificationCode
      }
    });
    this.url = 'api/phones/:id';
    return ret;
  }
}
