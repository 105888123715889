import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResource } from './base.resource';

@Injectable({
  providedIn: 'root'
})
export class NoteResource extends BaseResource {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = 'api/notes';
  }
}
