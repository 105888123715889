import { FormControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { TemplateAddons } from './run/addon';
import {
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldCheckboxen,
  FormlyFieldRadio,
  FormlyFieldRadioButtons,
  FormlyFieldRadioButtonsGroup,
  FormlyFieldSelect,
  FormlyFieldTextArea,
  FormlyFieldEmail,
  FormlyFieldDatePicker,
  FormlyFieldFile,
  FormlyFieldPhone,
  FormlyFieldName,
  FormlyFieldReadonly,
  FormlyRepeat
} from './types/types';

import {
  FormlyWrapperFormField,
  FormlyHorizontalWrapper
} from './wrappers/wrappers';

export const FIELD_TYPE_COMPONENTS = [
  // types
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldCheckboxen,
  FormlyFieldRadio,
  FormlyFieldRadioButtons,
  FormlyFieldRadioButtonsGroup,
  FormlyFieldSelect,
  FormlyFieldTextArea,
  FormlyFieldEmail,
  FormlyFieldFile,
  FormlyFieldPhone,
  FormlyFieldDatePicker,
  FormlyFieldName,
  FormlyFieldReadonly,
  FormlyRepeat,

  // wrappers
  FormlyWrapperFormField,
  FormlyHorizontalWrapper
];

export function PriceValidator(control: FormControl): ValidationErrors {
  return control.value > 0 ? null : { price: true };
}

export function PriceValidatorMessage(err, field: FormlyFieldConfig) {
  return `'${field.formControl.value}' is not a valid IP Address`;
}

export const BOOTSTRAP_EXTEND_FORMLY_CONFIG: ConfigOption = {
  types: [
    {
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    },
    {
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          indeterminate: true,
          hideLabel: true
        }
      }
    },
    {
      name: 'checkboxen',
      component: FormlyFieldCheckboxen,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'radio-buttons',
      component: FormlyFieldRadioButtons,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'radio-buttons-group',
      component: FormlyFieldRadioButtonsGroup,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          cols: 1,
          rows: 1
        }
      }
    },
    {
      name: 'email',
      component: FormlyFieldEmail,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'file',
      component: FormlyFieldFile,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'date-picker',
      component: FormlyFieldDatePicker,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'phone',
      component: FormlyFieldPhone,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'readonly',
      component: FormlyFieldReadonly,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: [],
          placeholder: '-'
        }
      }
    },
    {
      name: 'name',
      component: FormlyFieldName,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    { name: 'repeat', component: FormlyRepeat, wrappers: ['form-field'] }
  ],
  wrappers: [
    { name: 'form-field', component: FormlyWrapperFormField },
    { name: 'form-field-horizontal', component: FormlyHorizontalWrapper }
  ],
  manipulators: [{ class: TemplateAddons, method: 'run' }],
  validators: [{ name: 'price', validation: PriceValidator }],
  validationMessages: [
    { name: 'required', message: 'Bitte füllen Sie das aus' },
    { name: 'price', message: 'Bitte geben Sie ein richtig Wert ein!' },
    { name: 'min', message: 'Bitte geben Sie ein richtig Wert ein!' },
    { name: 'max', message: 'Bitte geben Sie ein richtig Wert ein!' }
  ]
};
