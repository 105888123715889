import { Action, createReducer, on } from '@ngrx/store';

import * as  LayoutActions from '../actions';
import { Layout } from '@clemos/web/features/exoplanets/models';

export const featureKey = 'layout';

export interface LayoutState extends Layout {}

export const initialState: LayoutState = {
  showSidebar: false,
  showAside: false,
  showQuickview: false
};

const layoutReducer = createReducer(
  initialState,

  on(LayoutActions.openSidebar, state => ({
    ...state,
    showSidebar: true
  })),
  on(LayoutActions.closeSidebar, state => ({
    ...state,
    showSidebar: false
  })),
  on(LayoutActions.toggleAside, state => ({
    ...state,
    showAside: !state.showAside
  })),
  on(LayoutActions.closeAside, state => ({
    ...state,
    showAside: false
  })),
  on(LayoutActions.openQuickview, state => ({
    ...state,
    showQuickview: true
  })),
  on(LayoutActions.closeQuickview, state => ({
    ...state,
    showQuickview: false
  })),
);

export function reducer(state: LayoutState | undefined, action: Action) {
  return layoutReducer(state, action);
}
