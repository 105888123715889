import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Lead } from '../models';
// import { Store } from '@ngxs/store';
// import { LoadDeal } from '../store';

@Injectable({
  providedIn: 'root'
})
export class DealResolver implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    // return this.store.dispatch(new LoadDeal(route.params.id));
  }
}
