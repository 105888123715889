import { Action, createReducer, on } from '@ngrx/store';
import * as LeadWizardActions from '../actions';
import {LeadCalculator} from '@clemos/calculators';
import { Lead, Customer, defaultPropertyCost } from '../../models';
import { LeadModule } from '@clemos/features';

export interface LeadWizardState {
  lead: Lead;
  customer?: Customer;
  formControl?: any;
  stepTitle: string;
  currentStep: number;
  lastFinishedStep: number;
  submitting: boolean;
}

export const initialState: LeadWizardState = {
  lead: {
    productCode: 'private_loan_mortgage',
    institutionIds: [],
    loan: {
      tenure: 25,
      amount: 0,
      downPayment: 0
    },
    property: {
      purpose: 'purchase',
      ...defaultPropertyCost
    },
    household: {},
    applicants: [{}]
  },
  customer: {
    email: ''
  },
  formControl: {
    showConstructionCost: false,
    showPurchaseCost: false,
    showOtherPurchaseCost: false,
    showWithBroker: false,
    showBrokerCommission: false,
    showDebtRestructuringCost: false,
    showFurnitureCost: false,
    showRenovationCost: false,
    showUpgradingCost: false,
    showLandscapingCost: false,
    showEnclosureCost: false
  },
  stepTitle: '',
  currentStep: 0,
  lastFinishedStep: 0,
  submitting: false
};

// function modifyUserState(
//   userState: UserState,
//   userChanges: Partial<User>
// ): UserState {
//   return {
//     ...userState,
//     loading: false,
//     users: userState.users.map(h => {
//       if (h.identifier === userChanges.identifier) {
//         return { ...h, ...userChanges };
//       } else {
//         return h;
//       }
//     })
//   };
// }

const leadWizardReducer = createReducer(
  initialState,
  on(LeadWizardActions.updateLeadWizard, (state, { lead, customer }) => {
    const oldLead = state.lead;
    const calculator = new LeadCalculator(lead, oldLead)
    return { ...state, 
      lead: {
        ...state.lead,
        property: calculator.property,
        loan: calculator.loan,
        applicants: calculator.applicants,
        household: calculator.household
      },
      formControl: calculator.formControl
    };
  }),
  on(LeadWizardActions.submitLeadWizard, state => ({ ...state, submitting: true })),
  on(LeadWizardActions.submitLeadWizardSuccess, state => ({ ...state, submitting: false })),
  on(LeadWizardActions.submitLeadWizardError, state => ({ ...state, submitting: false })),
);

export function reducer(state: LeadWizardState | undefined, action: Action) {
  return leadWizardReducer(state, action);
}
