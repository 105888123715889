import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InstitutionUser } from '../../models';
import { InstitutionUserResource } from '../../resources';

@Injectable({
  providedIn: 'root'
})
export class InstitutionUserOptions {
  constructor(private institutionUserResource: InstitutionUserResource) {}

  get(): Observable<InstitutionUser[]> {
    return this.institutionUserResource
      .query()
      .pipe(map(res => res.data));
  }
}
