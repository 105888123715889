import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { MenuItem, MenuSelectors } from '@clemos/core';

@Component({
  selector: 'clemos-exoplanets-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems$: Observable<MenuItem[]>;

  constructor(private menuSelectors: MenuSelectors) { 
    this.menuItems$ = this.menuSelectors.sidebar$;
   }

  @HostBinding('class.sidebar')
  @HostBinding('class.sidebar-expand-lg')
  @HostBinding('class.sidebar-iconic')
  @HostBinding('attr.debug-id')
  ngOnInit(): void { }

  ngOnDestroy(): void {}
}
