import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as MenuActions from '../actions';
import { EntityState } from '../reducers';
import { MenuItem } from '@clemos/core';

@Injectable()
export class MenuDispatchers {
  constructor(private store: Store<EntityState>) {}

  setSidebarMenu(menuItems: MenuItem[]) {
    this.store.dispatch(MenuActions.setSidebarMenu({ menuItems }));
  }

  setTopbarMenu(menuItems: MenuItem[]) {
    this.store.dispatch(MenuActions.setTopbarMenu({menuItems}));
  }

  setUserMenu(menuItems: MenuItem[]) {
    this.store.dispatch(MenuActions.setUserMenu({menuItems}));
  }

}
