import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

// components
import { LAYOUT_CONTAINERS } from './containers';
import {  LAYOUT_COMPONENTS } from './components';


@NgModule({
  imports: [SharedModule],
  declarations: [...LAYOUT_CONTAINERS, ...LAYOUT_COMPONENTS],
  exports: [...LAYOUT_CONTAINERS, ...LAYOUT_COMPONENTS]
})
export class LayoutModule {}
