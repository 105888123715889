import { createReducer, on } from '@ngrx/store';

import * as MenuActions from '../actions';
import { MenuItem } from '@clemos/core';

export const LayoutFeatureKey = 'menu';

export interface MenuState {
  sidebar: MenuItem[];
  topbar: MenuItem[];
  user: MenuItem[];
}

const initialState: MenuState = {
  sidebar: [],
  topbar: [],
  user: [],
};

export const reducer = createReducer(
  initialState,

  on(MenuActions.setSidebarMenu, (state, { menuItems }) => ({
    ...state,
    sidebar: menuItems
  })),
  on(MenuActions.setTopbarMenu, (state, { menuItems }) => ({
    ...state,
    topbar: menuItems
  })), 
  on(MenuActions.setUserMenu, (state, { menuItems }) => ({
    ...state,
    user: menuItems
  }))
);
