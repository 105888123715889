import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as LeadActions from '../actions';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LeadResource } from '../../resources';

@Injectable()
export class LeadEffects {
  getLeads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadActions.getLeads),
      switchMap(action =>
        this.leadResource.query(action.params).pipe(
          map(res =>
            LeadActions.getLeadsSuccess({ leads: res.data, meta: res.meta })
          ),
          tap(() => console.log('get lead')),
          catchError(error => of(LeadActions.getLeadsError({ error })))
        )
      )
    )
  );

  getLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadActions.getLead),
      switchMap(action =>
        this.leadResource.get(action.id).pipe(
          map(res => LeadActions.getLeadSuccess({ lead: res.data })),
          catchError(error => of(LeadActions.getLeadError({ error })))
        )
      )
    )
  );

  //   addLead$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(LeadActions.addLead),
  //       concatMap(action =>
  //         this.leadDataService.addLead(action.lead).pipe(
  //           map(lead => LeadActions.addLeadSuccess({ lead })),
  //           catchError(error => of(LeadActions.addLeadError({ error })))
  //         )
  //       )
  //     )
  //   );

  //   addLeadSuccess$ = createEffect(
  //     () =>
  //       this.actions$.pipe(
  //         ofType(LeadActions.addLeadSuccess),
  //         tap(() => this.toastrService.success('Lead created successful')),
  //         tap(() => this.ngbModel.dismissAll()),
  //         tap(action => this.router.navigate(['/inside/leads', action.lead.id]))
  //       ),
  //     { dispatch: false }
  //   );

  //   updateLead$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(LeadActions.updateLead),
  //       concatMap(action =>
  //         this.leadDataService.updateLead(action.lead).pipe(
  //           map(lead => LeadActions.updateLeadSuccess({ lead })),
  //           catchError(error => of(LeadActions.updateLeadError({ error })))
  //         )
  //       )
  //     )
  //   );

  //   updateLeadSuccess$ = createEffect(
  //     () =>
  //       this.actions$.pipe(
  //         ofType(LeadActions.updateLeadSuccess),
  //         tap(() => this.toastrService.success('Lead updated successful'))
  //       ),
  //     { dispatch: false }
  //   );

  //   getLeadTransactions$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(LeadActions.getLeadTransactions),
  //       switchMap(action =>
  //         this.transactionDataService.getTransactions(action.page, action.leadIdentifier).pipe(
  //           map(res =>
  //             LeadActions.getLeadTransactionsSuccess({
  //               transactions: res.data,
  //               meta: res.meta
  //             })
  //           ),
  //           catchError(error =>
  //             of(LeadActions.getLeadTransactionsError({ error }))
  //           )
  //         )
  //       )
  //     )
  //   );

  constructor(
    private actions$: Actions,
    private leadResource: LeadResource,
    private router: Router,
    private ngbModel: NgbModal,
    private toastrService: ToastrService
  ) {}
}
