import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { InstitutionBranch } from '../../models';
import { InstitutionBranchResource } from '../../resources';

@Injectable({
  providedIn: 'root'
})
export class InstitutionBranchOptions {
  constructor(private institutionBranchResource: InstitutionBranchResource) {}

  get(): Observable<InstitutionBranch[]> {
    return this.institutionBranchResource
      .query()
      .pipe(map(res => res.data));
  }
}
