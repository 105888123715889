import { NgModule } from '@angular/core';

// xplat
import { ClemosCoreModule } from '@clemos/web';


@NgModule({
  imports: [ClemosCoreModule]
})
export class CoreModule {}
