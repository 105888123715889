import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeOfEmploymentOptions {
  options = [
    { value: 'male', label: 'Herr' },
    { value: 'female', label: 'Frau' }
  ];

  get(): Observable<any[]> {
    return of(this.options);
  }
}
