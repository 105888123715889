import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio-buttons',
  template: `
    <div class="radio-buttons row">
      <div
        class="col-lg-6"
        *ngFor="
          let option of (to.options | formlySelectOptions: field | async);
          let i = index
        "
      >
        <div class="radio-container">
          <input
            type="radio"
            [id]="id + '_' + i"
            [class.form-check-input]="to.formCheck !== 'custom'"
            [class.custom-control-input]="to.formCheck === 'custom'"
            [name]="id"
            [class.is-invalid]="showError"
            [attr.value]="option.value"
            [value]="option.value"
            [formControl]="formControl"
            [formlyAttributes]="field"
            class="radio-button"
          />
          <label
            class="radio-tile"
            [class.form-check-label]="to.formCheck !== 'custom'"
            [class.custom-control-label]="to.formCheck === 'custom'"
            [for]="id + '_' + i"
          >
            <strong>{{ option.label }}</strong>
          </label>

          <!-- <div class="radio-tile">
          <span
            class="radio-tile-label"
            [class.form-check-label]="to.formCheck !== 'custom'"
            [class.custom-control-label]="to.formCheck === 'custom'"
          >
            
          </span>
        </div> -->
        </div>
      </div>
    </div>
  `
})
export class FormlyFieldRadioButtons extends FieldType {}
