import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Lead } from '../models';
// import { Store } from '@ngxs/store';
// import { LoadLead } from '../store';

@Injectable({
  providedIn: 'root'
})
export class LeadResolver implements Resolve<any> {
  constructor(
    // private store: Store
    ) {}

  // resolve(route: ActivatedRouteSnapshot): Observable<Lead> {
    // return this.store.dispatch(new LoadLead(route.params.id));
  // }
  
  resolve (route: ActivatedRouteSnapshot) {
    return true;
  }
}
