import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const camelcase = require('camelcase');

@Injectable({
    providedIn: 'root'
})
export class MortgageOptions {
    propertyPurpose = [
        { value: 'purchase', label: 'Immobilie kaufen' },
        { value: 'debt_restructuring', label: 'Umschulden' },
        { value: 'renovation', label: 'Sanieren' },
    ];

    propertyType = [
        { value: 'condominium', label: 'Eigentumswohnung' },
        { value: 'house', label: 'Haus' },
        { value: 'plot', label: 'Grundstück' },
        { value: 'others', label: 'Sonstiges / Gewerbeobjekt' }
    ];


    get(key): Observable<any[]> {
        return of(this[camelcase(key)]);
    }
}
