import { ActionReducerMap } from '@ngrx/store';

import * as fromLeads from './lead.reducer';
import * as fromDeals from './deal.reducer';
import * as fromLayout from './layout.reducer';
import * as fromMenu from './menu.reducer';
import * as fromLeadWizard from './lead-wizard.reducer';


export interface EntityState {
  leads: fromLeads.LeadState;
  deals: fromDeals.DealState;
  layout: fromLayout.LayoutState;
  menu: fromMenu.MenuState;
  leadWizard: fromLeadWizard.LeadWizardState;
}

export const reducers: ActionReducerMap<EntityState> = {
  leads: fromLeads.reducer,
  deals: fromDeals.reducer,
  layout: fromLayout.reducer,
  menu: fromMenu.reducer,
  leadWizard: fromLeadWizard.reducer,
};
