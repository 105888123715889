import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyBootstrapAddonsModule } from './addons';
import { UISharedModule } from '@clemos/features/ui';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';

import {
  BOOTSTRAP_EXTEND_FORMLY_CONFIG,
  FIELD_TYPE_COMPONENTS
} from './bootstrap-extend.config';
import { DoFloatDirective } from './directives/do-float.directive';
import { ScrollToFirstInvalidDirective } from './directives/scroll-to-first-invalid.directive';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';


const DIRECTIVES = [DoFloatDirective, ScrollToFirstInvalidDirective];

const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: false,
  decimal: ',',
  precision: 0,
  prefix: '€ ',
  suffix: '',
  thousands: '.',
  nullable: true
};

@NgModule({
  declarations: [FIELD_TYPE_COMPONENTS, ...DIRECTIVES],
  imports: [
    CommonModule,
    UISharedModule,
    FormsModule,
    ReactiveFormsModule,
    FormlySelectModule,
    FormlyModule.forChild(BOOTSTRAP_EXTEND_FORMLY_CONFIG),
    FormlyBootstrapAddonsModule,

    // CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    // FormlySelectModule,
    NgbModule,
    TextMaskModule,
    // FormlyModule.forRoot(CUSTOM_FORMLY_CONFIG),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    TranslateModule,
    NgxDatatableModule,
    // NgSelectModule,
    // IntlTelInputNgModule.forRoot(),
    // Ng2TelInputModule,
    InternationalPhoneNumberModule
  ],
  exports: [DIRECTIVES]
})
export class FormlyBootstrapExtendModule {}
