import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// libs
import { UISharedModule } from '@clemos/features';

// Third-party Libs
import { FormlyModule } from '@ngx-formly/core';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoaderIndicatorModule } from 'ngx-loader-indicator';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  UISharedModule,
  FormlyModule,
  MomentModule,
  FontAwesomeModule,
  NgbModule,
  NgxLoaderIndicatorModule,
  NgxUploaderModule,
  NgxJsonViewerModule,
  PerfectScrollbarModule
];

@NgModule({
  imports: [...MODULES],
  declarations: [],
  exports: [...MODULES]
})
export class UIModule {}
