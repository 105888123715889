import { createAction, props } from '@ngrx/store';

import { Deal, Meta } from '../../models';
import { DataServiceError } from '../../errors';

export const createDealAction = (actionType: string) =>
  createAction(actionType, props<{ deal: Deal }>());

export const createDealErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: DataServiceError<Deal> }>());

export const getDeals = createAction(
  '[Deal] GET_DEALS',
  props<{ params: any }>()
);

export const getDealsSuccess = createAction(
  '[Deal] GET_DEALS_SUCCESS',
  props<{ deals: Deal[]; meta: Meta }>()
);

export const getDealsError = createDealErrorAction('[Deal] GET_DEALS_ERROR');

export const addDeal = createDealAction('[Deal] ADD_DEAL');

export const addDealSuccess = createDealAction('[Deal] ADD_DEAL_SUCCESS');

export const addDealError = createDealErrorAction('[Deal] ADD_DEAL_ERROR');

export const getDeal = createAction('[Deal] GET_DEAL', props<{ id: string }>());

export const getDealSuccess = createDealAction('[Deal] GET_DEAL_SUCCESS');

export const getDealError = createDealErrorAction('[Deal] GET_DEAL_ERROR');

export const createDeal = createDealAction('[Deal] CREATE_LEAD');

export const createDealSuccess = createDealAction('[Deal] CREATE_LEAD_SUCCESS');

export const createDealError = createDealErrorAction(
  '[Deal] CREATE_LEAD_ERROR'
);

export const updateDeal = createDealAction('[Deal] UPDATE_DEAL');

export const updateDealSuccess = createDealAction('[Deal] UPDATE_DEAL_SUCCESS');

export const updateDealError = createDealErrorAction(
  '[Deal] UPDATE_DEAL_ERROR'
);

export const setDealLoading = createAction(
  '[Deal] SET_DEAL_LOADING',
  props<{ loading: boolean }>()
);
