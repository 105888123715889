import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// App
import { AuthResolver } from '@clemos/core';
import { InsideComponent } from './features/layout';

// Libs
import { AngularTokenService } from 'angular-token';

const routes: Routes = [
  {
    path: 'inside',
    component: InsideComponent,
    canActivate: [AngularTokenService],
    resolve: {
      currentUser: AuthResolver
    },
    children: [
      {
        path: 'leads',
        loadChildren: () =>
          import('./features/lead/lead.module').then(m => m.LeadModule)
      },
      {
        path: '',
        redirectTo: 'leads',
        pathMatch: 'full'
      }
    ],
  },
  {
    path: 'lead-wizard',
    loadChildren: () =>
      import('./features/lead-wizard/lead-wizard.module').then(m => m.LeadWizardModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    redirectTo: 'lead-wizard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
