export * from './xplat-window.interface';

export * from './institution/user.model';
export * from './institution/branch.model';
export * from './institution/company.model';

export * from './messenger/conversation.model';
export * from './messenger/message.model';

export * from './applicant.model';
export * from './bank.model';
export * from './coach.model';
export * from './customer.model';
export * from './deal.model';
export * from './document.model';
export * from './job.model';
export * from './lead.model';
export * from './note.model';
export * from './menu-item.model';
export * from './photo-url.model';
export * from './product.model';
export * from './profile.model';
export * from './phone.model';
export * from './offer.model';
export * from './task.model';
export * from './resident.model';
export * from './enquiry/mortgage.model';
export * from './meta.model';
export * from './invoice.model';

export * from './response.model';
