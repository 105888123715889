import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { MenuItem, MenuSelectors } from '@clemos/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'clemos-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  menuItems$: Observable<MenuItem[]>;


  constructor(private menuSelectors: MenuSelectors) {
    this.menuItems$ = this.menuSelectors.topbar$;
  }

  ngOnInit() {

  }
}
