import { Action, createReducer, on } from '@ngrx/store';
import { Lead, Meta } from '../../models';
import * as LeadActions from '../actions';

export interface LeadState {
  entities: Lead[];
  selectedEntity?: Lead;
  meta?: Meta;
  loading: boolean;
  error: boolean;
}

const initialState: LeadState = {
  entities: [],
  meta: {
    currentPage: 0,
    nextPage: 0,
    prevPage: 0,
    totalPages: 0,
    totalCount: 0
  },
  loading: false,
  error: false
};

const leadReducer = createReducer(
  initialState,
  on(LeadActions.createLead, state => ({ ...state, loading: true })),
  on(LeadActions.createLeadSuccess, (state, { lead }) => ({
    ...state,
    loading: false,
    entities: [...state.entities, { ...lead }]
  })),
  on(LeadActions.createLeadError, state => ({ ...state, loading: false })),
  on(LeadActions.getLeads, state => ({ ...state, loading: true })),
  on(LeadActions.getLeadsError, state => ({ ...state, loading: false })),
  on(LeadActions.getLeadsSuccess, (state, { leads, meta }) => ({
    ...state,
    loading: false,
    entities: leads,
    meta
  })),
  on(LeadActions.getLead, state => ({ ...state, loading: true })),
  on(LeadActions.getLeadError, state => ({ ...state, loading: false })),
  on(LeadActions.getLeadSuccess, (state, { lead }) => ({
    ...state,
    loading: false,
    selectedEntity: lead
  })),
  on(LeadActions.updateLead, (state, { lead }) => ({
    ...state,
    entities: state.entities.map(h => {
      if (h.id === lead.id) {
        state.loading = true;
      }
      return h;
    })
  })),
  on(LeadActions.updateLeadSuccess, (state, { lead }) =>
    // modifyLeadState(state, lead)
    // {...state}
    {
      return state;
    }
  ),
  on(LeadActions.updateLeadError, (state, { error }) => ({
    ...state,
    entities: state.entities.map(h => {
      if (h.id === error.requestData.id) {
        // Huh? No idea what the error is!
        state.error = true;
      }
      return h;
    }),
    loading: false
  })),
  on(LeadActions.setLeadLoading, (state, { loading }) => ({
    ...state,
    loading: loading == null ? true : loading
  }))
);

export function reducer(state: LeadState | undefined, action: Action) {
  return leadReducer(state, action);
}
