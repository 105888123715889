import { createAction, props } from '@ngrx/store';

import { Lead, Customer } from '@clemos/core';

export const updateLeadWizard = createAction(
  '[Lead Wizard] UPDATE_LEAD_WIZARD',
  props<{ lead: Lead; customer: Customer }>()
);

export const submitLeadWizard = createAction(
  '[Lead Wizard] SUBMIT_LEAD_WIZARD'
);

export const submitLeadWizardSuccess = createAction(
  '[Lead Wizard] SUBMIT_LEAD_WIZARD_SUCCESS'
);

export const submitLeadWizardError = createAction(
  '[Lead Wizard] SUBMIT_LEAD_WIZARD_ERROR',
  props<{ error: any }>()
);
