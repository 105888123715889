import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class YearOptions {
    options = [];

    get(): Observable<any[]> {
        for (let i = 1; i <= 40; i++) {
            this.options.push({ value: i, label: i });
        }
        return of(this.options);
    }
}
