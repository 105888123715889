import { LeadDispatchers } from './lead.dispatchers';
import { LeadSelectors } from './lead.selectors';
import { DealDispatchers } from './deal.dispatchers';
import { DealSelectors } from './deal.selectors';
import { MenuDispatchers } from './menu.dispatchers';
import { MenuSelectors } from './menu.selectors';
import { LeadWizardDispatchers } from './lead-wizard.dispatchers';
import { LeadWizardSelectors } from './lead-wizard.selectors';

export const STORE_SERVICES = [
  LeadDispatchers,
  LeadSelectors,
  DealDispatchers,
  DealSelectors,
  MenuDispatchers,
  MenuSelectors,
  LeadWizardDispatchers,
  LeadWizardSelectors,
];

export * from './lead.dispatchers';
export * from './lead.selectors';
export * from './deal.dispatchers';
export * from './deal.selectors';
export * from './menu.dispatchers';
export * from './menu.selectors';
export * from './lead-wizard.dispatchers';
export * from './lead-wizard.selectors';
