import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-name',
  template: `
    <div class="row" >
      <div class="col-md-6">
        <input
          type="text"
          [(ngModel)]="firstName"
          class="form-control"
          [class.is-invalid]="showError"
          placeholder="Vorname"
        />
      </div>
      <div class="col-md-6">
        <input
          type="text"
          [(ngModel)]="lastName"
          class="form-control"
          [class.is-invalid]="showError"
          placeholder="Name"
        />
      </div>
    </div>
  `
})
export class FormlyFieldName extends FieldType {
  firstName = this.model[this.field.key]['firstName'];
  lastName = this.model[this.field.key]['lastName'];
}
