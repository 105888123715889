import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LeadWizardActions from '../actions';
import { Lead, Customer } from '@clemos/core';
import { EntityState } from '../reducers';

@Injectable()
export class LeadWizardDispatchers {
  constructor(private store: Store<EntityState>) {}

  update(lead: Lead, customer: Customer) {
    this.store.dispatch(LeadWizardActions.setLeadLoading({loading: true}))
    this.store.dispatch(LeadWizardActions.updateLeadWizard({ lead, customer }));
  }

  submit() {
    this.store.dispatch(LeadWizardActions.submitLeadWizard());
  }
}
