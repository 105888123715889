import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Coach } from '../models';
import { CoachResource } from '../resources';

@Injectable({
  providedIn: 'root'
})
export class CoachOptions {
  constructor(private coachResource: CoachResource) {}

  get(): Observable<Coach[]> {
    return this.coachResource.query().pipe(map(res => res['coaches']));
  }
}
