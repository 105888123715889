import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DealStatusOptions {
  options = [
    {value: 'awaiting', label: 'Neue'},
    {value: 'prepare', label: 'Vorbereitung'},
    {value: 'offer_sent', label: 'Angebote verschickt'},
    {value: 'approving', label: 'Riskoüberprüfung '},
    {value: 'finalizing', label: 'Finalisieren'},
    {value: 'won', label: 'Gewinn'},
    {value: 'lost', label: 'Lost'},
    {value: 'rejected', label: 'Abgelehnt'},
    {value: 'withdrawn', label: 'Zurückgezogen'}
  ];

  get(): Observable<any[]> {
    return of(this.options);
  }
}
