import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Ngrx 
import { StoreModule } from '@ngrx/store';

// Xplat shared UI modules 
import { UIModule } from '@clemos/web/features/ui';

// Xplat containers
import { InsideComponent } from './containers/inside/inside.component';

// Xplat components
import { COMPONENTS } from './components';

// Xplat directives
import { AsideDirective } from './directives/aside.directive';

// 3. party modules
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapExtendModule } from '../formly-bootstrap-extend';
import { FormlyModule } from '@ngx-formly/core';

export const containers = [InsideComponent];

export const directives = [AsideDirective];

@NgModule({
  imports: [
    // CommonModule,
    // RouterModule,
    // PerfectScrollbarModule,
    // FontAwesomeModule,
    // TranslateModule,
    // FormsModule,
    // ReactiveFormsModule,
    // FormlyModule,
    // FormlyBootstrapExtendModule,
    // NgbModule,
    UIModule,
  ],
  declarations: [...containers, ...COMPONENTS, ...directives],
  exports: [...COMPONENTS, ...directives],
  providers: [  ]
})
export class ExoplanetsModule {}
