// Angular core modules
import { NgModule } from '@angular/core';

// app
import { environment } from '../environments/environment';

// Ngrx modules
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormSyncModule, FORM_SYNC_STORE_KEY, storageSync } from '@larscom/ngrx-store-storagesync';

export function storageSyncReducer(reducer: ActionReducer<any>) {
  // provide all feature states within the features array
  // features which are not provided, do not get synced
  const sync = storageSync<any>({
    features: [
      // save only router state to sessionStorage
      { stateKey: 'clemosCore', storageForFeature: window.sessionStorage },
    ],
    // defaults to localStorage
    storage: window.localStorage
  });

  return sync(reducer);
}

export const metaReducers: MetaReducer<any>[] = environment.production
  ? [storageSyncReducer]
  : [storageSyncReducer]; // [storeFreeze];

@NgModule({
  imports: [
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ]
})
export class NgxsConfigModule {}
