import { createAction, props } from '@ngrx/store';

import { Lead, Meta } from '../../models';
import { DataServiceError } from '../../errors';

export const createLeadAction = (actionType: string) =>
  createAction(actionType, props<{ lead: Lead }>());

export const createLeadErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: DataServiceError<Lead> }>());

export const getLeads = createAction(
  '[Lead] GET_LEADS',
  props<{ params: any }>()
);

export const getLeadsSuccess = createAction(
  '[Lead] GET_LEADS_SUCCESS',
  props<{ leads: Lead[]; meta: Meta }>()
);

export const getLeadsError = createAction(
  '[Lead] GET_LEADS_ERROR',
  props<{ error: any }>()
);

export const createLead = createLeadAction('[Lead] CREATE_LEAD');

export const createLeadSuccess = createLeadAction('[Lead] CREATE_LEAD_SUCCESS');

export const createLeadError = createLeadErrorAction(
  '[Lead] CREATE_LEAD_ERROR'
);

export const getLead = createAction('[Lead] GET_LEAD', props<{ id: string }>());

export const getLeadSuccess = createLeadAction('[Lead] GET_LEAD_SUCCESS');

export const getLeadError = createLeadErrorAction('[Lead] GET_LEAD_ERROR');

export const updateLead = createLeadAction('[Lead] UPDATE_LEAD');

export const updateLeadSuccess = createLeadAction('[Lead] UPDATE_LEAD_SUCCESS');

export const updateLeadError = createLeadErrorAction(
  '[Lead] UPDATE_LEAD_ERROR'
);

export const setLeadLoading = createAction(
  '[Lead] SET_LEAD_LOADING',
  props<{ loading: boolean }>()
);
