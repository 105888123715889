import { NgModule } from '@angular/core';

// libs
import { environment } from '@clemos/core';

// app
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// features
import { LayoutModule } from './features/layout';
import { SharedModule } from './features/shared/shared.module';

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, LayoutModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
