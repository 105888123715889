import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { map, tap, mergeMap, catchError } from 'rxjs/operators';
// import { Store } from '@ngxs/store';
// import { SetCurrentUser } from '../store/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthResolver implements Resolve<any> {
  constructor(
    private tokenService: AngularTokenService,
    private router: Router,
    // private store: Store
  ) {}

  resolve(): Observable<any> {
    return this.tokenService.validateToken().pipe(
      catchError(err => of(this.router.navigate(['auth', 'sign-in']))),
      map(res => res['data']),
      tap(user => {
        // this.store.dispatch(new SetCurrentUser(user));
      })
    );
  }
}
