import {
  Component,
  Output,
  OnInit,
  EventEmitter,
  Input
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'clemos-exoplanets-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() filterFields: FormlyFieldConfig[];
  @Output() search = new EventEmitter<any>();

  searchParams: any = {};
  form = new FormGroup({});

  constructor() {}

  submit() {
    this.search.emit(this.searchParams);
  }

  reset() {
    this.search.emit(this.searchParams);
  }

  ngOnInit() {}
}
